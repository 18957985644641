import { Box, Paper, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import washImg from './SVG/wash.svg';
import dryImg from './SVG/dry.svg';
import trimImg from './SVG/trim.svg';
import toothbrushImg from './SVG/toothbrush.svg';
import Constants from './Constants';
import { Img } from './Utils';

const StyledItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center ',
}));
const ItemName = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
}));
const Item = (props: { img: string; text: string }) => {
  const { img, text } = props;
  return (
    <StyledItem
      sx={{
        flexDirection: { xs: 'row', sm: 'column' },
        alignItems: 'center',
      }}
    >
      <Img
        sx={{ mb: { xs: 0, sm: 3 }, mr: { xs: 3, sm: 0 } }}
        src={img}
        width={{ xs: '100px', md: '50%' }}
        alt={text}
      />
      <ItemName
        fontSize={{ xs: '1rem', md: '2vw' }}
        textAlign={{ xs: 'left', sm: 'center' }}
        alignItems="center"
        justifyContent={{ xs: 'flex-start', sm: 'center' }}
      >
        {text}
      </ItemName>
    </StyledItem>
  );
};

export default function Services() {
  return (
    <Box
      pl={{ xs: 2, sm: 4 }}
      pr={{ xs: 2, sm: 4 }}
      textAlign="center"
      id="services"
    >
      <Typography
        variant="h2"
        mt={{ xs: 0, sm: 2 }}
        mb={3}
        fontSize={Constants.responsiveFontSizes.h2}
      >
        Experience The Gentle Spaw Treatment
      </Typography>
      <Typography
        variant="h4"
        fontSize={Constants.responsiveFontSizes.h4}
        mb={{ xs: 4, sm: 6 }}
      >
        A Pawsitively Spa-Like Grooming Experience
      </Typography>
      <Grid
        px={{ xs: 0, sm: 5, md: 12 }}
        mb={{ xs: 0, sm: 3 }}
        container
        spacing={{ xs: 2, md: 5 }}
        justifyContent="center"
      >
        <Grid xs={12} sm={6}>
          <Item img={washImg} text="Warm, hydrating baths" />
        </Grid>
        <Grid xs={12} sm={6}>
          <Item img={dryImg} text="Gentle brushing and blow dry" />
        </Grid>
        <Grid xs={12} sm={6}>
          <Item img={toothbrushImg} text="Teeth cleaning" />
        </Grid>
        <Grid xs={12} sm={6}>
          <Item img={trimImg} text="Professional breed-specific grooming" />
        </Grid>
      </Grid>
    </Box>
  );
}
