import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import '@fontsource/sintony';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import './App.scss';
import { Header } from './Header';
import { Main } from './Main';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const API_ROOT = IS_DEV ? 'http://localhost:4001/' : '/api/';

export const LocationsText = {
  default:
    'Mobile Dog Grooming Servicing Calabasas, Temecula, Malibu, Thousand Oaks, Woodland Hills and surrounding cities in LA',
  calabasas:
    'Mobile Dog Grooming Servicing Calabasas, Malibu, Thousand Oaks, Woodland Hills and surrounding cities in LA',
  temecula: 'Mobile Dog Grooming Servicing Temecula and surrounding areas',
};
export type LocationNames = 'calabasas' | 'temecula';
type LocationContextType = {
  location: LocationNames | null;
  setLocation: React.Dispatch<React.SetStateAction<LocationNames | null>>;
};
export const LocationContext = React.createContext<LocationContextType>(
  {} as LocationContextType,
);
export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: '"Sintony", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Faustina", serif',
      fontWeight: '800',
      marginBottom: '14px',
    },
    h2: {
      fontFamily: '"Faustina", serif',
      marginBottom: '12px',
      fontWeight: '800',
    },
    h3: {
      marginBottom: '12px',
    },
    h4: {
      marginBottom: '12px',
    },
  },
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#ed1c24',
    },
  },
};
const mdTheme = createTheme(themeOptions);

function App() {
  const [location, setLocation] = useState<LocationNames | null>(null);
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf-vDglAAAAAL2sWWuU8HtBe8ymBvHx5YX0CjFr">
      <ThemeProvider theme={mdTheme}>
        <ScopedCssBaseline>
          <LocationContext.Provider value={{ location, setLocation }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Header />
              <Main />
            </LocalizationProvider>
          </LocationContext.Provider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
