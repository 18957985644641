import { Facebook, Instagram } from '@mui/icons-material';
import { Box, IconButton, Link, Typography } from '@mui/material';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import About from './About';
import { Contact } from './Contact';
import { FAQ } from './FAQ';
import Hero from './Hero';
import Services from './Services';
import { Testmonials } from './Testimonials';
export const initialHeight = {
  xs: 'calc(100vh - 88px)',
  sm: 'calc(100vh - 124px)',
};
export const phoneNumber = '310-993-5993';
export const MainPhone = () => {
  return <>{phoneNumber}</>;
};
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ pt: 4 }}
    >
      Copyright&nbsp;
      {new Date().getFullYear()}&nbsp;&copy;&nbsp;
      <Link color="inherit" href="https://thegentlespaw.com">
        The Gentle Spaw
      </Link>
    </Typography>
  );
}
function Social() {
  return (
    <Typography variant="body1" align="center" pt={2}>
      <IconButton href="https://www.facebook.com/TheGentleSpaw/">
        <Facebook sx={{ fontSize: '50px' }} />
      </IconButton>
      <IconButton href="https://www.instagram.com/thegentlespaw/">
        <Instagram sx={{ fontSize: '50px' }} />
      </IconButton>
    </Typography>
  );
}

export const Main = () => {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          mb: 4,
          minHeight: initialHeight,
        }}
      >
        <Hero />
        <Testmonials />
        <Services />
        <About />
        <FAQ />
        <Contact />
        <Box
          textAlign="center"
          color="text.secondary"
          justifyContent="center"
          display="flex"
          width="100%"
        >
          <MilitaryTechIcon /> Veteran Owned
        </Box>
        <Copyright />
        <Social />
      </Box>
    </Box>
  );
};
