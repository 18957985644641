import { useCallback, useRef, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import Constants from './Constants';
import { MainPhone, phoneNumber } from './Main';
import { API_ROOT } from './App';
type ContactInput = {
  email: string;
  name: string;
  phone: string;
  message: string;
  token: string;
};
export const Contact = () => {
  return (
    <Box px={4} py={6} textAlign="center">
      <Button variant="contained" color="secondary" href={`tel:${phoneNumber}`}>
        <Typography
          m={0}
          variant="h4"
          fontSize={Constants.responsiveFontSizes.h4}
          textTransform="none"
          p={1}
        >
          Book Your Gentle Spaw Day Now @ <MainPhone />
        </Typography>
      </Button>
      <ContactForm />
    </Box>
  );
};

const ContactForm = () => {
  const [disabled, setDisabled] = useState(true);
  const [complete, setComplete] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [token, setToken] = useState('');
  const refs = {
    name: useRef<HTMLInputElement>(),
    email: useRef<HTMLInputElement>(),
    phone: useRef<HTMLInputElement>(),
    message: useRef<HTMLInputElement>(),
  };
  const handleSend = async () => {
    setDisabled(true);
    let fieldName: keyof typeof refs;
    const body = { token } as ContactInput;
    for (fieldName in refs) {
      const field = refs[fieldName];
      if (!field.current) {
        continue;
      }
      if (!field.current.validity.valid) {
        if (field.current.validity.valueMissing) {
          alert('You must fill in all fields, please try again');
        } else {
          alert(`Please enter a valid value for ${fieldName}.`);
        }
        return;
      }
      body[fieldName] = field.current.value;
    }

    if (!token) {
      return;
    }

    await fetch(API_ROOT + 'contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    setComplete(true);
  };

  const setCaptcha = useCallback((t: string) => {
    setToken(t);
    setDisabled(false);
  }, []);

  if (complete) {
    return (
      <Box px={4} py={6} textAlign="center">
        <Typography
          mt={4}
          variant="h4"
          fontSize={Constants.responsiveFontSizes.h4}
          textTransform="none"
        >
          Your message has been sent, somebody will be in touch soon!
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Typography
        variant="h2"
        fontSize={Constants.responsiveFontSizes.h2}
        textTransform="none"
        mt={4}
      >
        Contact Us
      </Typography>
      <Paper
        sx={{
          px: 4,
          py: 4,
          textAlign: 'center',
          mt: 2,
          width: '400px',
          maxWidth: '100%',
          mx: 'auto',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: '100%', sm: '350px' }}
          mx="auto"
          mt={2}
        >
          <TextField
            label="Name"
            type="text"
            variant="filled"
            color="secondary"
            inputRef={refs.name}
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="filled"
            color="secondary"
            inputRef={refs.email}
            required
          />
          <MuiTelInput
            forceCallingCode
            disableDropdown
            label="Phone"
            variant="filled"
            color="secondary"
            inputRef={refs.phone}
            onChange={(newPhone) => {
              setPhoneValue(newPhone);
            }}
            inputProps={{ pattern: '[\\d]{3} [\\d]{3} [\\d]{4}' }}
            value={phoneValue}
            required
          />
          <TextField
            label="Message"
            type="text"
            multiline={true}
            variant="filled"
            color="secondary"
            inputRef={refs.message}
            required
          />
          <GoogleReCaptcha onVerify={setCaptcha} />
          <Button
            disabled={disabled}
            sx={{ mt: 2 }}
            color="secondary"
            variant="contained"
            onClick={handleSend}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </>
  );
};
