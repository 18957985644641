import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Box,
  Typography,
} from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Constants from './Constants';

export default function Discounts() {
  return (
    <Box>
      <List>
        <ListItem>
          <ListItemAvatar>
            <AttachMoneyIcon />
          </ListItemAvatar>
          <ListItemText>
            <Typography
              variant="h5"
              fontSize={Constants.responsiveFontSizes.h5}
            >
              New Customer
            </Typography>
            Get 15% off your first grooming
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <PetsIcon />
          </ListItemAvatar>
          <ListItemText>
            <Typography
              variant="h5"
              fontSize={Constants.responsiveFontSizes.h5}
            >
              Referrals
            </Typography>
            Refer a Friend and get 10% OFF your next grooming!
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar>
            <MilitaryTechIcon />
          </ListItemAvatar>
          <ListItemText>
            <Typography
              variant="h5"
              fontSize={Constants.responsiveFontSizes.h5}
            >
              Veterans
            </Typography>
            Veterans get 10% OFF all services!
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
}
