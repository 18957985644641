import { Box, Typography } from '@mui/material';
import Constants from './Constants';
import dogSmile from './dog-smile.jpg';

export default function About() {
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection={{ xs: 'column', sm: 'row' }}
      px={4}
      py={4}
    >
      <Box flex={1} mr={{ xs: 0, sm: 4 }}>
        <img
          style={{ width: '100%' }}
          src={dogSmile}
          alt="Dogs smile after leaving The Gentle Spaw"
        />
      </Box>
      <Box flex={1}>
        <Typography
          variant="h2"
          mt={{ xs: 1, sm: 0 }}
          mb={2}
          textAlign={{ xs: 'center', sm: 'left' }}
          fontSize={Constants.responsiveFontSizes.h2}
        >
          We Love Animals!
        </Typography>
        The Gentle Spaw team is committed to bringing the luxury of pet styling
        to your doorstep. Our state-of-the-art mobile grooming bus is fully
        equipped to provide your furry friend with a safe and relaxing spa day
        right outside the comfort of your own home. We understand that grooming
        is an essential aspect of your dog's health and well-being, and our
        animal-loving professionals will deliver a consistent and top-quality
        grooming experience. Choose The Gentle Spaw for a stress-free and
        trustworthy grooming service that puts your pet's needs first.
      </Box>
    </Box>
  );
}
