import { Button, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { LocationContext, LocationsText } from './App';
import Constants from './Constants';
import vanImg from './gentle-spaw-van.jpg';
import { initialHeight, MainPhone, phoneNumber } from './Main';
const styles = {
  heroImage: {
    backgroundImage: `url(${vanImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
  },
};
export default function Hero() {
  const { location } = useContext(LocationContext);
  let locationText = LocationsText.default;
  if (location && LocationsText[location]) {
    locationText = LocationsText[location];
  }
  return (
    <Box
      id="hero"
      minHeight={initialHeight}
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
        flex={1}
        spacing={2}
        mt={0}
        height={{ xs: 'auto', md: '100%' }}
      >
        <Grid
          sm={12}
          item={true}
          md={6}
          bgcolor="white"
          borderRadius={3}
          padding={2}
        >
          <Box
            minHeight="100%"
            display="flex"
            alignContent="center"
            flexDirection="row"
          >
            <Box
              width={{ xs: '100%', md: '50%' }}
              margin="auto"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              <Typography
                variant="h1"
                fontSize={Constants.responsiveFontSizes.h1}
              >
                Dog Grooming at Your Door
              </Typography>
              <Typography
                variant="h4"
                p={{ xs: 1, sm: 0 }}
                fontSize={Constants.responsiveFontSizes.h4}
              >
                {locationText}
              </Typography>
              <Typography
                variant="h2"
                fontSize={Constants.responsiveFontSizes.h2}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  href={`tel:${phoneNumber}`}
                  size="large"
                >
                  Book Now @ <MainPhone />
                </Button>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} item={true} md={6} style={styles.heroImage}></Grid>
      </Grid>
    </Box>
  );
}
