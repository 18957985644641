import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import Constants from './Constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pricing from './Pricing';
import Discounts from './Discounts';
import { MainPhone } from './Main';

export function FAQ() {
  return (
    <Box id="faq">
      <Typography
        textAlign="center"
        variant="h2"
        mb={4}
        fontSize={Constants.responsiveFontSizes.h2}
      >
        Frequently Asked Questions
      </Typography>
      <Box px={{ xs: 5, sm: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Are there any additional fees for some services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Pricing />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Do you offer any discounts?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Discounts />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Do you take any precautions for COVID?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            Because we care so much about the safety of our customers, The
            Gentle Spaw team is fully vaccinated, always wears a mask during
            interactions, and follows COVID safe cleaning protocols after every
            groom.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Do you have any size restrictions?</Typography>
          </AccordionSummary>
          <AccordionDetails>We groom all dogs, big and small!</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>What's your pricing?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            The Gentle Spaw has fair prices for all our services. Prices can
            differ depending on the breed, temperment, size, and condition of
            their coat, and the services you select. Our services typically
            range from $120-$350+. Give us a call at <MainPhone />
            &nbsp; to get a quote and book today!
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>What kind of products do you use?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            We use all-natural, high-quality products
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
