import { Box } from '@mui/material';

export default function Pricing() {
  return (
    <Box>
      <p>
        Please note that additional fees may apply for certain grooming services
        depending on the condition of your dog's coat and skin. These fees
        include:
      </p>
      <ul>
        <li>Flea and tick treatments: $30-$50</li>
        <li>Matting or lack of grooming: $40-$80</li>
        <li>
          Sanitation fee: $50 or more, depending on the extent of the mess.
        </li>
        <li>
          Extra hydrating baths: $30+ depending on the severity of the skin
          condition and size of dog.
        </li>
      </ul>
      <p>
        We always communicate any additional fees upfront and will never
        surprise you with unexpected charges. Contact us today to schedule an
        appointment and give your dog the pampering they deserve.
      </p>
    </Box>
  );
}
