import { Star } from '@mui/icons-material';
import { Box, Link, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Constants from './Constants';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    message:
      'They handled our pets with great care. Awesome service. Highly recommended.',
    from: 'Tommy M. on Yelp',
    url: 'https://www.yelp.com/biz/the-gentle-spaw-calabasas?hrid=cHIL9WNagYvDtYvo1yPfwg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
  },
  {
    message:
      'Great service! I have a rescue Boxer mix w/anxiety. She warmed up to him pretty quickly. I am now going to be a monthly customer for sure!',
    from: 'Felicia on Yelp',
    url: 'https://www.yelp.com/biz/the-gentle-spaw-calabasas?hrid=nm56JAveugnPHdrGou6gFw&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
  },
  {
    message:
      'Outstanding!! Amazing!! If I could give more stars I would! They were amazing. Very professional, quick and thorough.',
    from: 'Teddy on Yelp',
    url: 'https://www.yelp.com/biz/the-gentle-spaw-calabasas?hrid=lSed9is_IwIi015euaRDNg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
  },
  {
    message:
      "Thank goodness we found the Gentle Spa, because this is the best cut and service we've ever experienced!",
    from: 'Michelle on Yelp',
    url: 'https://www.yelp.com/biz/the-gentle-spaw-calabasas?hrid=2fL19e1TBmC54hAY5UhYlA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
  },
];

const RedStar = () => (
  <Star
    sx={{
      color: 'secondary.main',
      fontSize: '38px',
      lineHeight: '1em',
    }}
  />
);

export function Testmonials() {
  return (
    <Box padding={1}>
      <Typography
        variant="h2"
        fontSize={Constants.responsiveFontSizes.h1}
        textAlign="center"
        mt={3}
        mb={3}
      >
        Five Star Care
        <br />
        <RedStar />
        <RedStar />
        <RedStar />
        <RedStar />
        <RedStar />
      </Typography>

      <Grid container spacing={4} margin={1}>
        {testimonials.map((testimonial) => {
          return (
            <Grid xs={12} sm={6} key={testimonial.message}>
              <Testimonial {...testimonial} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

const Testimonial = (props: { message: string; from: string; url: string }) => {
  const { message, from, url } = props;
  return (
    <Paper
      sx={{
        padding: 2,
        height: '100%',
        color: '#fff',
        backgroundColor: 'secondary.main',
      }}
    >
      <Box display="flex" flexDirection={'column'} height="100%">
        <FormatQuoteIcon sx={{ fontSize: '36px' }} />
        <Box sx={{ fontFamily: '"Faustina", serif', fontSize: '30px' }}>
          {message}
        </Box>
        <Box
          flex={1}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          -&nbsp;
          <Link color="primary" fontWeight="bold" href={url}>
            {from}
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};
