import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext } from 'react';
import logoImg from './logo.png';
import { MainPhone, phoneNumber } from './Main';
import { Img } from './Utils';
import { LocationContext, LocationNames } from './App';
const headerLinkStyles = {
  color: 'primary.contrastText',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '16px',
};
export const Header = () => {
  const { setLocation } = useContext(LocationContext);
  const [anchorElNavMobile, setAnchorElNavMobile] =
    React.useState<null | HTMLElement>(null);
  const [anchorElLocationsNav, setAnchorElLocationsNav] =
    React.useState<null | HTMLElement>(null);

  const handleOpenMobileNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNavMobile(event.currentTarget);
  };
  const handleCloseMobileNavMenu = () => {
    setAnchorElNavMobile(null);
  };
  const handleOpenLocationsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLocationsNav(event.currentTarget);
  };
  const handleCloseLocationsMenu = (newLocation: LocationNames) => {
    setLocation(newLocation);
    setAnchorElLocationsNav(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography>
          <Img
            src={logoImg}
            alt="The Gentle Spaw"
            sx={{ width: { xs: '100px', sm: '150px' } }}
            mt={1}
          />
        </Typography>
        <Box px={2} display={{ xs: 'none', sm: 'block' }}>
          <Button
            variant="text"
            sx={headerLinkStyles}
            aria-label="Locations dropdown"
            aria-controls="menu-locations"
            aria-haspopup="true"
            onClick={handleOpenLocationsMenu}
            color="inherit"
          >
            Locations
          </Button>
          <Button variant="text" sx={headerLinkStyles} href="#services">
            Services
          </Button>
        </Box>
        <Box
          sx={{
            display: { xs: 'block', sm: 'none' },
            flex: 1,
            textAlign: 'right',
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenMobileNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          flexGrow={1}
          textAlign="right"
          alignItems="center"
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          <Typography variant="h6" flexGrow={1}>
            Call or text: &nbsp;
          </Typography>
          <Typography variant="h6" color="secondary">
            <Button
              variant="contained"
              color="secondary"
              sx={{ fontSize: 'inherit' }}
            >
              <MainPhone />
            </Button>
          </Typography>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNavMobile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNavMobile)}
          onClose={handleCloseMobileNavMenu}
          sx={{
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <MenuItem disabled>Locations</MenuItem>
          <MenuItem
            sx={{ paddingLeft: 4 }}
            onClick={() => {
              setLocation('calabasas');
              handleCloseMobileNavMenu();
            }}
            component={Link}
            href="#?location=calabasas"
          >
            Calabasas
          </MenuItem>
          <MenuItem
            sx={{ paddingLeft: 4 }}
            onClick={() => {
              setLocation('temecula');
              handleCloseMobileNavMenu();
            }}
            component={Link}
            href="#?location=temecula"
          >
            Temecula
          </MenuItem>
          <MenuItem
            onClick={handleCloseMobileNavMenu}
            component={Link}
            href="#services"
          >
            Services
          </MenuItem>
          <MenuItem
            onClick={handleCloseMobileNavMenu}
            component={Link}
            href="#faq"
          >
            Frequently Asked Questions
          </MenuItem>
          <MenuItem
            onClick={handleCloseMobileNavMenu}
            component={Link}
            href={`tel:${phoneNumber}`}
          >
            <MainPhone />
          </MenuItem>
        </Menu>
        <Menu
          id="menu-locations"
          anchorEl={anchorElLocationsNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElLocationsNav)}
          onClose={handleCloseLocationsMenu}
        >
          <MenuItem
            onClick={() => handleCloseLocationsMenu('calabasas')}
            component={Link}
            href="#?location=calabasas"
          >
            Calabasas
          </MenuItem>
          <MenuItem
            onClick={() => handleCloseLocationsMenu('temecula')}
            component={Link}
            href="#?location=temecula"
          >
            Temecula
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
